@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: -0.01em;
  font-family: Arial, Helvetica, sans-serif;
}

.nav-item {
  font-size: 12px; /* 更小的字体大小 */
  font-weight: bold;
  line-height: 1.2; /* 更紧凑的行高 */
  letter-spacing: -0.01em; /* 稍微减小字母间距 */
  font-family: 'Arial Rounded MT Bold', 'Helvetica Rounded', Arial, sans-serif; /* 一个相对紧凑的无衬线字体 */
}

.p-2 {
  padding: 0.4rem;
}

.p-3 {
  padding: 0.35rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.calendar-header {
  display: flex;
  padding: 1rem;
  flex-direction: row;
  align-items: baseline;
  padding: 10px;
}

.calendar-nav-btn {
  font-size: 1.25rem;
  font-weight: bold;
}

.calendar-title {
  flex-grow: 1;
  text-align: center;
}

.h-8 {
  height: 1.4rem;
}
.mb-5 {
  margin-bottom: 0.5rem;
}

.rounded-sm {
  border-radius: 0.2rem;
}
.border-l-gradient-to-b {
  border-left-width: 4px;
  border-image: linear-gradient(to bottom, #4ade80, #facc15) 1 100%;
}
.text-2xl {
  font-size: 1.2rem;
  line-height: 2rem;
}

.text-rg {
  font-size: 1rem;
  line-height: 1.25rem;
}

.text-xsm {
  font-size: 0.675rem;
  line-height: 1.25rem;
}

.text-xxsm {
  font-size: 0.475rem;
  line-height: 1.25rem;
}

.rounded-t-lg {
  border-top-left-radius: 0 rem;
  border-top-right-radius: 0 rem;
}


/* 定义不同的鼠标样式 */
.cursor-pencil,
.cursor-highlighter,
.cursor-eraser {
  cursor: none;
}

/* 铅笔鼠标样式 */
.cursor-pencil::after {
  content: '\f303';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 24px;
  color: #0e0101;
  position: absolute;
  left: calc(var(--cursor-x) + 13px);
  top: calc(var(--cursor-y) + 13px);
  transform: translate(-50%, -50%) scaleY(-1);
  pointer-events: none;
  z-index: 1000;
}

/* 荧光笔鼠标样式 */
.cursor-highlighter::after {
  content: '\f591';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 24px;
  color: #0f0101;
  position: absolute;
  left: calc(var(--cursor-x) + 10px);
  top: calc(var(--cursor-y) + 10px);
  transform: translate(-50%, -50%) scaleY(-1);
  pointer-events: none;
  z-index: 1000;
}

/* 橡皮擦鼠标样式 */
.cursor-eraser::after {
  content: '\f12d';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 24px;
  color: #0b0101;
  position: absolute;
  left: var(--cursor-x, 0);
  top: var(--cursor-y, 0);
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1000;
}

.quill {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ql-container {
  flex: 1;
  overflow-y: auto;
}

.ql-container {
  background-color: rgb(207, 183, 183);
}

.ql-editor {
  background-color: white;
}

.ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgb(226, 203, 158);
}


.ql-editor .phonetics {
  color: #0000FF;
  font-style: italic;
}

.fixed.inset-0.bg-black.bg-opacity-50 button {
  background-color: rgba(209, 213, 219, 0.8) !important;
  transition: background-color 0.2s ease-in-out !important;
}

.fixed.inset-0.bg-black.bg-opacity-50 button:hover {
  background-color: rgba(16, 185, 129, 0.8) !important;
}

.fixed.inset-0.bg-black.bg-opacity-50 button svg {
  width: 1.5rem !important;
  height: 1.5rem !important;
  color: #4B5563 !important;
}

.fixed.inset-0.bg-black.bg-opacity-50 button:hover svg {
  color: #FFFFFF !important;
}